import * as React from 'react'

import Layout from '../components/layout'

const IndexPage = () => {
  return (
    <Layout title="Overview">
      <h1>Web Application Optimization</h1>
      <p className="usa-intro">
        Focus on performance, accessibility, and best practices to deliver the
        best customer experience.
      </p>
      <p>
        At OneGlobe, we build software for the U.S. Government. That software
        serves the public good, supporting missions like individual disaster
        assistance, infrastructure and preparedness grants, citizenship
        benefits, and national defense logistics. These solutions directly
        affect the lives of Americans, and we build them to that standard.
      </p>
      <p>
        When people interact with our software they form a lasting impression of
        the organizations and agencies we support. As such, we can support a positive user 
        experience with key, focused software optimizations which we describe here.
      </p>
      <p>
        Much of the software we deliver is web applications and services, and we know that anyone who has opened a webpage has 
        strong expectations for this kind of software. With OneGlobe software, customers should expect the same responsiveness, 
        accessibility, and predictable interactions that they receive from private or commercial services.
      </p>
      <h2>We do it every day</h2>
      <p>This website was built with everything we just described in mind. We use <a target="_blank" rel="noreferrer"
      className="usa-link" href='https://www.gatsbyjs.com/'>Gatsby.js</a>, a React-based static site generator 
      powered by GraphQL that is excellent for building performant websites. Our website design 
      follows <a rel="noreferrer" target="_blank" href='https://designsystem.digital.gov/design-principles/'>United 
      States Web Design System (USWDS) 3.0 standards</a>, and 
      pulls CSS classes straight from their SASS library for developers. Gatsby pulls together React components, SASS, and plugins into 
      lightweight static production files that we serve from our AWS CloudFront distribution. This is just a single implementation representing
      our standards for building optimal web applications.</p>
      <h2 id="approach">The Approach</h2>
      <p>
        Our approach to web application optimization is based on the following
        aspects. Each section provides further information about delivery
        considerations for that aspect.
      </p>
      <ul className='usa-list'>
        <li>
          <strong>
            <a className="usa-link" href="/performance">
              Performance
            </a>
          </strong>
          . Loading times, page behaviors, transfer optimizations. Build for the
          users in disaster housing when the broadband has been destroyed by a
          hurricane. Responsive software is better than unresponsive software.
        </li>
        <li>
          <strong>
            <a className="usa-link" href="/accessibility">
              Accessibility
            </a>
          </strong>
          . Fortunately, section 508 of the Rehabilitation Act is a legal
          requirement. We strive to go beyond basic accessibility standards, 
          because accessible and inclusive software is quality, user-centric software.
        </li>
        <li>
          <strong>
            <a className="usa-link" href="/bestpractices">
              Best Practices
            </a>
          </strong>
          . General good ideas for browser interactions, connectivity, and
          security.
        </li>
        <li>
          <strong>
            <a className="usa-link" href="/seo">
              SEO
            </a>
          </strong>
          . Search engine optimization makes services easier to find. Some
          people use search engines as their primary means of navigating the
          internet.
        </li>
      </ul>
      <h3 id="wheel">The Wheel</h3>
      <p>
        Technology works because of interoperability, standards, and conventions.
        A tremendous amount of work has gone into developing, maturing, and
        curating the solid foundations upon which modern service delivery is
        built.
      </p>
      <p>
        This is not about reinventing the wheel. This is about how we
        incorporate these foundations into the way we do things, and how we
        apply these practices to deliver quality products for our customers and
        for the public good.
      </p>
      <h3 id="hcd">Human-Centered Design</h3>
      <p>
        We cannot overstate the value of human-centered
        design (HCD) and the role it plays in delivering a positive customer
        experience. We incorporate HCD and good design principles into our delivery model, 
        and the optimization patterns covered here ensure that the outputted design is delivered effectively.
      </p>
      <p>
        After all, having the most effective design in the world is less
        likely to spark joy if it takes 5 seconds to load the page or someone
        using assistive technology is unable to even access it.
      </p>
    </Layout>
  )
}

export default IndexPage
